<template>
  <div
    ref="mainContainer"
    :class="`${campaignData.theme_type === 'without_card' ? 'without_card container-class' : 'container-class'}`"
    :style="{ backgroundImage: `url(${campaignData.bg_image})` }"
  >
    <div class="d-flex flex-column sub-container">
      <!-- <power-by
        v-if="
          campaignData.powered_by_text &&
          campaignData.theme_type === 'verticle'
        "
        :campaignData="campaignData"
      /> -->
      <div
        class="card-view pd-5 text-center"
        v-bind:style="
          isWinner ? winnerStyle.winnerCardStyle : loserStyle.loserCardStyle
        "
        v-if="campaignData.theme_type === 'verticle'"
      >
        <div class="result_content_wrapper">
          <img
            alt="Sponsor Image"
            class="sponsor_logo"
            v-if="resultData.sponsor_logo"
            :src="resultData.sponsor_logo"
          />
          <div
            v-bind:style="{
              display: 'flex',
              flexDirection: isWinner ? 'column' : 'column-reverse',
            }"
          >
            <div class="price_box">
              <h2
                v-bind:style="
                  isWinner
                    ? winnerStyle.winnerHeaderStyle
                    : loserStyle.loserHeaderStyle
                "
                class="title-class"
                style="padding-bottom: 0px"
              >
                {{
                  isWinner
                    ? campaignData.success_title
                    : campaignData.fail_title
                }}
              </h2>
              <p
                v-bind:style="
                  isWinner
                    ? winnerStyle.winnerTextStyle
                    : loserStyle.loserTextStyle
                "
                class="msg-1"
              >
                {{
                  isWinner
                    ? campaignData.success_description
                    : campaignData.fail_description
                }}
              </p>
              <h1
                v-if="isWinner"
                v-bind:style="winnerStyle.winnerPrizeNameStyle"
                class="result-name"
              >
                {{
                  resultData ? resultData.prize : ""
                }}
              </h1>
              <div v-if="isWinner && resultData">
                <p
                  v-bind:style="winnerStyle.winnerPrizeDescriptionStyle"
                  class="result-desc"
                  v-if="isWinner && resultData.prize_description"
                >
                  {{ resultData.prize_description }}
                </p>
              </div>
              <div class="result_content_div">
                <div v-if="isWinner && prizeImageUrl" class="image-thumb-cls">
                  <img alt="Prize Image" :src="prizeImageUrl" />
                  <div
                    v-if="isWinner && resultData"
                    class="result-value"
                    v-bind:style="winnerStyle.winnerBadgeStyle"
                  >
                    <div class="result-value-inner">
                      <p
                        v-if="isWinner && campaignData.prize_badge_text"
                        v-bind:style="winnerStyle.winnerBadgeTextStyle"
                      >
                        {{ campaignData.prize_badge_text }}
                      </p>
                      <h1
                        v-if="isWinner && resultData.prize_value"
                        v-bind:style="winnerStyle.winnerPrizeValueStyle"
                      >
                        {{
                          resultData.position == 1
                            ? resultData.currency
                            : ""
                        }}{{ resultData.prize_value }}
                        {{
                          resultData.position == 0
                            ? resultData.currency
                            : ""
                        }}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="isWinner && resultData">
                <div
                  :class="
                    resultData.discount_code
                      ? 'discount-code-container'
                      : ''
                  "
                >
                  <p
                    v-bind:style="winnerStyle.winnerPrizeDiscountStyle"
                    class="discount-code"
                    v-if="isWinner && resultData.discount_code"
                  >
                    {{ resultData.discount_code }}
                  </p>
                </div>
                <div
                  v-if="
                    resultData.discount_expiry_datetime &&
                    isValidDate(
                      resultData.discount_expiry_datetime
                    ) &&
                    !isTimeOver(
                      resultData.discount_expiry_datetime
                    )
                  "
                  class="countdown-container"
                >
                  <sdSimpleCountdown
                    v-if="
                      resultData.discount_expiry_datetime &&
                      isValidDate(
                        resultData.discount_expiry_datetime
                      )
                    "
                    :trans="trans"
                    :starttime="currentTime()"
                    @timeElapsed="onTimeComplete"
                    :endtime="resultData.discount_expiry_datetime"
                  />
                </div>
              </div>
            </div>
            <div
              class="price_box"
              v-if="!isWinner && campaignData.loser_popup_image"
            >
              <div class="result_content_div">
                <div
                  class="image-thumb-cls"
                  v-bind:style="{ marginBottom: '20px !important' }"
                >
                  <img
                    v-if="campaignData.loser_popup_image"
                    alt="Prize Image"
                    :src="campaignData.loser_popup_image"
                  />
                </div>
              </div>
            </div>
          </div>

          <button
            style="width: 100%; margin: 0 auto; margin-top: 20px"
            v-if="
              (isWinner && campaignData.success_btn_title) ||
              (!isWinner && campaignData.fail_btn_title)
            "
            class="submit-btn"
            @click="handleBtnPress"
            v-bind:style="
              isWinner
                ? winnerStyle.winnerButtonStyle
                : loserStyle.loserButtonStyle
            "
          >
            {{
              isWinner
                ? campaignData.success_btn_title
                : campaignData.fail_btn_title
            }}
          </button>
        </div>
      </div>
      
      <div
        class="pd-5 text-center card-view"
        v-bind:style="
          isWinner ? winnerStyle.winnerCardStyle : loserStyle.loserCardStyle
        "
        v-else-if="campaignData.theme_type === 'without_card'"
      >
        <div class="result_content_wrapper">
          <img
            alt="Sponsor Image"
            class="sponsor_logo"
            v-if="resultData.sponsor_logo"
            :src="resultData.sponsor_logo"
          />
          <div
            v-bind:style="{
              display: 'flex',
              flexDirection: isWinner ? 'column' : 'column-reverse',
            }"
          >
            <div class="price_box">
              <h2
                v-bind:style="
                  isWinner
                    ? winnerStyle.winnerHeaderStyle
                    : loserStyle.loserHeaderStyle
                "
                class="title-class"
                style="padding-bottom: 0px"
              >
                {{
                  isWinner
                    ? campaignData.success_title
                    : campaignData.fail_title
                }}
              </h2>
              <p
                v-bind:style="
                  isWinner
                    ? winnerStyle.winnerTextStyle
                    : loserStyle.loserTextStyle
                "
                class="msg-1"
              >
                {{
                  isWinner
                    ? campaignData.success_description
                    : campaignData.fail_description
                }}
              </p>
              <h1
                v-if="isWinner"
                v-bind:style="winnerStyle.winnerPrizeNameStyle"
                class="result-name"
              >
                {{
                  resultData ? resultData.prize : ""
                }}
              </h1>
              <div v-if="isWinner && resultData">
                <p
                  v-bind:style="winnerStyle.winnerPrizeDescriptionStyle"
                  class="result-desc"
                  v-if="isWinner && resultData.prize_description"
                >
                  {{ resultData.prize_description }}
                </p>
              </div>
              <div class="result_content_div">
                <div v-if="isWinner && prizeImageUrl" class="image-thumb-cls">
                  <img alt="Prize Image" :src="prizeImageUrl" />
                  <div
                    v-if="isWinner && resultData"
                    class="result-value"
                    v-bind:style="winnerStyle.winnerBadgeStyle"
                  >
                    <div class="result-value-inner">
                      <p
                        v-if="isWinner && campaignData.prize_badge_text"
                        v-bind:style="winnerStyle.winnerBadgeTextStyle"
                      >
                        {{ campaignData.prize_badge_text }}
                      </p>
                      <h1
                        v-if="isWinner && resultData.prize_value"
                        v-bind:style="winnerStyle.winnerPrizeValueStyle"
                      >
                        {{
                          resultData.position == 1
                            ? resultData.currency
                            : ""
                        }}{{ resultData.prize_value }}
                        {{
                          resultData.position == 0
                            ? resultData.currency
                            : ""
                        }}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="isWinner && resultData">
                <div
                  :class="
                    resultData.discount_code
                      ? 'discount-code-container'
                      : ''
                  "
                >
                  <p
                    v-bind:style="winnerStyle.winnerPrizeDiscountStyle"
                    class="discount-code"
                    v-if="isWinner && resultData.discount_code"
                  >
                    {{ resultData.discount_code }}
                  </p>
                </div>
                <div
                  v-if="
                    resultData.discount_expiry_datetime &&
                    isValidDate(
                      resultData.discount_expiry_datetime
                    ) &&
                    !isTimeOver(
                      resultData.discount_expiry_datetime
                    )
                  "
                  class="countdown-container"
                >
                  <sdSimpleCountdown
                    v-if="
                      resultData.discount_expiry_datetime &&
                      isValidDate(
                        resultData.discount_expiry_datetime
                      )
                    "
                    :trans="trans"
                    :starttime="currentTime()"
                    @timeElapsed="onTimeComplete"
                    :endtime="resultData.discount_expiry_datetime"
                  />
                </div>
              </div>
            </div>
            <div
              class="price_box"
              v-if="!isWinner && campaignData.loser_popup_image"
            >
              <div class="result_content_div">
                <div
                  class="image-thumb-cls"
                  v-bind:style="{ marginBottom: '20px !important' }"
                >
                  <img
                    v-if="campaignData.loser_popup_image"
                    alt="Prize Image"
                    :src="campaignData.loser_popup_image"
                  />
                </div>
              </div>
            </div>
          </div>

          <button
            style=""
            v-if="
              (isWinner && campaignData.success_btn_title) ||
              (!isWinner && campaignData.fail_btn_title)
            "
            class="submit-btn"
            @click="handleBtnPress"
            v-bind:style="
              isWinner
                ? winnerStyle.winnerButtonStyle
                : loserStyle.loserButtonStyle
            "
          >
            {{
              isWinner
                ? campaignData.success_btn_title
                : campaignData.fail_btn_title
            }}
          </button>
        </div>
      </div>
      <div
        class="card-view pd-5 text-center"
        v-bind:style="
          isWinner ? winnerStyle.winnerCardStyle : loserStyle.loserCardStyle
        "
        v-else
      >
        <div class="d-flex align-items-center d-xs-block column-gap-40">
          <!-- for web view -->
          <div v-if="!isMobileDevice()">
            <div v-if="isWinner" class="price_box">
              <div class="result_content_div">
                <div v-if="isWinner && prizeImageUrl" class="image-thumb-cls">
                  <img alt="Prize Image" :src="prizeImageUrl" />
                  <div
                    v-if="isWinner && resultData"
                    class="result-value"
                    v-bind:style="winnerStyle.winnerBadgeStyle"
                  >
                    <div class="result-value-inner">
                      <p
                        v-if="isWinner && campaignData.prize_badge_text"
                        v-bind:style="winnerStyle.winnerBadgeTextStyle"
                      >
                        {{ campaignData.prize_badge_text }}
                      </p>
                      <h1
                        v-if="isWinner && resultData.prize_value"
                        v-bind:style="winnerStyle.winnerPrizeValueStyle"
                      >
                        {{
                          resultData.position == 1
                            ? resultData.currency
                            : ""
                        }}{{ resultData.prize_value }}
                        {{
                          resultData.position == 0
                            ? resultData.currency
                            : ""
                        }}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="isWinner && resultData">
                <div
                  :class="
                    resultData.discount_code
                      ? 'discount-code-container'
                      : ''
                  "
                >
                  <p
                    v-bind:style="winnerStyle.winnerPrizeDiscountStyle"
                    class="discount-code"
                    v-if="isWinner && resultData.discount_code"
                  >
                    {{ resultData.discount_code }}
                  </p>
                </div>
                <div
                  v-if="
                    resultData.discount_expiry_datetime &&
                    isValidDate(
                      resultData.discount_expiry_datetime
                    ) &&
                    !isTimeOver(
                      resultData.discount_expiry_datetime
                    )
                  "
                  class="countdown-container"
                >
                  <sdSimpleCountdown
                    v-if="
                      resultData.discount_expiry_datetime &&
                      isValidDate(
                        resultData.discount_expiry_datetime
                      )
                    "
                    :trans="trans"
                    :starttime="currentTime()"
                    @timeElapsed="onTimeComplete"
                    :endtime="resultData.discount_expiry_datetime"
                  />
                </div>
              </div>
            </div>
            <div class="price_box" v-else-if="campaignData.loser_popup_image">
              <div class="result_content_div">
                <div class="image-thumb-cls">
                  <img
                    alt="Prize Image"
                    v-if="campaignData.loser_popup_image"
                    :src="campaignData.loser_popup_image"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- for web view -->
          <div class="result_content_wrapper">
            <img
              alt="Sponsor Image"
              class="sponsor_logo"
              v-if="resultData.sponsor_logo"
              :src="resultData.sponsor_logo"
            />
            <h2
              v-bind:style="
                isWinner
                  ? winnerStyle.winnerHeaderStyle
                  : loserStyle.loserHeaderStyle
              "
              class="title-class"
              style="padding-bottom: 0px"
            >
              {{
                isWinner ? campaignData.success_title : campaignData.fail_title
              }}
            </h2>
            <p
              v-bind:style="
                isWinner
                  ? winnerStyle.winnerTextStyle
                  : loserStyle.loserTextStyle
              "
              class="msg-1"
            >
              {{
                isWinner
                  ? campaignData.success_description
                  : campaignData.fail_description
              }}
            </p>
            <div v-if="isWinner" class="price_box">
              <h1
                v-if="isWinner"
                v-bind:style="winnerStyle.winnerPrizeNameStyle"
                class="result-name"
              >
                {{
                  resultData ? resultData.prize : ""
                }}
              </h1>
              <div v-if="isWinner && resultData">
                <p
                  v-bind:style="winnerStyle.winnerPrizeDescriptionStyle"
                  class="result-desc"
                  v-if="isWinner && campaignData.prize_data.description"
                >
                  {{ resultData.prize_description }}
                </p>
              </div>
            </div>

            <!-- for mobile view -->
            <div v-if="isMobileDevice()">
              <div v-if="isWinner" class="price_box">
                <div class="result_content_div">
                  <div v-if="isWinner && prizeImageUrl" class="image-thumb-cls">
                    <img alt="Prize Image" :src="prizeImageUrl" />
                    <div
                      v-if="isWinner && resultData"
                      class="result-value"
                      v-bind:style="winnerStyle.winnerBadgeStyle"
                    >
                      <div class="result-value-inner">
                        <p
                          v-if="isWinner && campaignData.prize_badge_text"
                          v-bind:style="winnerStyle.winnerBadgeTextStyle"
                        >
                          {{ campaignData.prize_badge_text }}
                        </p>
                        <h1
                          v-if="isWinner && resultData.value"
                          v-bind:style="winnerStyle.winnerPrizeValueStyle"
                        >
                          {{
                            resultData.position == 1
                              ? cresultData.currency
                              : ""
                          }}{{ resultData.value }}
                          {{
                            resultData.position == 0
                              ? resultData.currency
                              : ""
                          }}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="isWinner && resultData">
                  <div
                    :class="
                      resultData.discount_code
                        ? 'discount-code-container'
                        : ''
                    "
                  >
                    <p
                      v-bind:style="winnerStyle.winnerPrizeDiscountStyle"
                      class="discount-code"
                      v-if="isWinner && resultData.discount_code"
                    >
                      {{ resultData.discount_code }}
                    </p>
                  </div>
                  <div
                    v-if="
                      resultData.discount_expiry_datetime &&
                      isValidDate(
                        resultData.discount_expiry_datetime
                      ) &&
                      !isTimeOver(
                        resultData.discount_expiry_datetime
                      )
                    "
                    class="countdown-container"
                  >
                    <sdSimpleCountdown
                      v-if="
                        resultData.discount_expiry_datetime &&
                        isValidDate(
                          resultData.discount_expiry_datetime
                        )
                      "
                      :trans="trans"
                      :starttime="currentTime()"
                      @timeElapsed="onTimeComplete"
                      :endtime="
                        resultData.discount_expiry_datetime
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="price_box" v-else-if="campaignData.loser_popup_image">
                <div class="result_content_div">
                  <div class="image-thumb-cls">
                    <img
                      alt="Prize Image"
                      v-if="campaignData.loser_popup_image"
                      :src="campaignData.loser_popup_image"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- for mobile view -->

            <button
              style="width: 55%; margin: 0 auto"
              v-if="
                (isWinner && campaignData.success_btn_title) ||
                (!isWinner && campaignData.fail_btn_title)
              "
              class="submit-btn"
              @click="handleBtnPress"
              v-bind:style="
                isWinner
                  ? winnerStyle.winnerButtonStyle
                  : loserStyle.loserButtonStyle
              "
            >
              {{
                isWinner
                  ? campaignData.success_btn_title
                  : campaignData.fail_btn_title
              }}
            </button>
          </div>
        </div>
      </div>
      <power-by
        v-if="
          campaignData.powered_by_text
          // && campaignData.theme_type !== 'verticle'
        "
        :campaignData="campaignData"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";

import PowerBy from "@/components/powerby/PowerBy.vue";

import { getUrl, isMobile } from "@/services/helperFunctions";

export default {
  name: "Page3",
  components: {
    PowerBy,
  },
  computed: {
    isWinner: function () {
      return this.$store.getters.isWinner;
    },
    campaignData: function () {
      return this.$store.getters.campaignData;
    },
    isSubscribed: function () {
      return this.$store.getters.isSubscribed;
    },
    isResultPageVisited: function () {
      return this.$store.getters.isResultPageVisited;
    },
    resultData: function () {
      return this.$store.getters.resultData;
    },
    winnerStyle: function () {
      return this.$store.getters.winnerStyle;
    },
    loserStyle: function () {
      return this.$store.getters.loserStyle;
    },
    authData: function () {
      return this.$store.getters.authData;
    },
  },
  data() {
    return {
      trans: {},
      prizeImageUrl: "",
    };
  },
  methods: {
    currentTime() {
      return moment().format("MMM DD, YYYY HH:mm:ss");
    },
    isMobileDevice() {
      return isMobile();
    },
    isValidDate(date) {
      return date && moment(date).isValid();
    },
    isTimeOver(date) {
      return moment(date).isSameOrBefore(moment());
    },
    onTimeComplete() {
      alert("Discount code is expired");
    },
    handleBtnPress() {
      if (this.isWinner && this.campaignData.success_url) {
        //window.open(this.campaignData.success_url, "_blank");
        window.open(
          getUrl(this.campaignData.success_url, this.authData),
          "_blank"
        );
      } else if (this.campaignData.fail_url) {
        //window.open(this.campaignData.fail_url, "_blank");
        window.open(
          getUrl(this.campaignData.fail_url, this.authData),
          "_blank"
        );
      }
    },
    checkStatus() {
      if (_.isEmpty(this.campaignData) || !this.isResultPageVisited) {
        this.$store.commit("setPageCount", 1);
      }
    },
    checkRedirect() {
      const { redirect_url = "", redirect_second = 0 } = this.campaignData;
      if (redirect_url && Number(redirect_second)) {
        const rUrl = getUrl(redirect_url, this.authData);
        setTimeout(() => {
          location.replace(rUrl);
        }, redirect_second * 1000);
      }
    },
  },
  created() {
    if (this.isWinner) {
      if (
        this.resultData &&
        this.resultData.prize_second_image != ""
      ) {
        this.prizeImageUrl = this.resultData.prize_second_image;
      } else {
        this.prizeImageUrl = this.campaignData.card_back;
      }

      console.log(this.resultData);
    } else {
      this.prizeImageUrl = this.campaignData.card_back;
    }
    this.trans = {
      day: "Day",
      hours: "Hours",
      minutes: "Minuts",
      seconds: "Seconds",
      expired: this.resultData.discount_expiry_datetime_label || "",
      running: this.resultData.discount_expiry_datetime_label || "",
      upcoming: this.resultData.discount_expiry_datetime_label || "",
      status: {
        expired: "Expired",
        running: "Running",
        upcoming: "Future",
      },
    };
  },
  mounted() {
    this.checkStatus();
    this.checkRedirect();
  },
};
</script>
